import { Component, OnInit } from "@angular/core";
import { ServicesService } from "../../services/services.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-service-detail",
  templateUrl: "./service-detail.component.html",
  styleUrls: ["./service-detail.component.scss"],
})
export class ServiceDetailComponent implements OnInit {
  serviceGot: any ={};
  modulesList: any[]=[];
  serviceMessage: string =""
  constructor(
    private services: ServicesService,
    private route: ActivatedRoute
  ) {
    const service = route.snapshot.paramMap.get('service')
    this.services.getServices().subscribe((res) => {
      this.serviceGot = res['body']['services'].find(res=>res.title=== service)
      this.modulesList = this.serviceGot['modules'].filter(res=>res.active)
      this.serviceMessage = `https://api.whatsapp.com/send?phone=573014694747&text=Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%3A%20${this.serviceGot.title}`


    });
  }

  ngOnInit(): void {
  }
}
//Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%3A%20