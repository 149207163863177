import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/shared/models/products';
import { CatalogService } from 'src/app/shared/services/catalog.service';
import { ShopService } from 'src/app/shared/services/shop.service';
import { environment } from 'src/environments/environment';
import { VariablesService } from '../../shared/services/variables.service';

@Component({
  selector: 'app-mis-compras',
  templateUrl: './mis-compras.component.html',
  styleUrls: ['./mis-compras.component.scss']
})
export class MisComprasComponent implements OnInit {
  public checkoutCarList:any=[];
  public itemFormGroup: FormGroup;
  public resumeGroup : FormGroup;
  public arrayForm:FormArray;
  public url =environment.urlBucketS3;
  public total:number=0;
  public catalogList:any=[];
  public message: string = '';
  public showMessage: boolean = false;
  public productsNoStockname: any =[];
  public totalItems: number;
  public numero: any;
  public totalCar;
  public decrement = 1;
  public storageCar;
  public finalStoragecar;
  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }

  constructor(private formBuilder:FormBuilder, 
    private catalogService:CatalogService, 
    private router:Router, 
    private shop:ShopService,
    private totalItemsCar: VariablesService,
    private variableService: VariablesService,
    private toastr: ToastrService ) { 


    this.itemFormGroup = formBuilder.group({
      items: formBuilder.array([])
    });

    this.catalogList = catalogService.getCatalog();

    this.resumeGroup = formBuilder.group({
      resumenSubtotal:[''],
      resumenDelivery:[''],
      resumenTotal:['']
    });


    if(localStorage.getItem('carList')!==null){
      if ( JSON.parse(localStorage.getItem('carList')).length >0) {
        this.checkoutCarList =JSON.parse(localStorage.getItem('carList'));
        this.checkoutCarList.forEach(element => {
          this.addItem(element);
          this.total = this.total + (parseFloat(element.count) * parseFloat(element.price));
        });
        this.resumeGroup = formBuilder.group({
          resumenSubtotal:[],
          resumenDelivery:[],
          resumenTotal:[ this.total   ,[Validators.required]]
        })
      }
    }

    this.checkProductAvailability();

    this.variableService.totalCar.subscribe(res => {
      this.totalCar = res;
    });

  }


  ngOnInit(): void {
    this.onResize()
  }

  public redirect(url){
    this.router.navigate([url]);
  }



  public checkProductAvailability(){
  /*  this.checkoutCarList.length > 0 ? this.shop.checkProductAvailability({items: this.checkoutCarList}).subscribe(response => {
      if (!response.body['success']) {
        return true;
      }else{
        response['body']['products'].forEach(productNoStock => {
          this.checkoutCarList.forEach(element => {
            if (productNoStock.ref === element.sk) {
              this.productsNoStockname.push(element.description);
            }
          });
        });
        this.showMessage = true;
        this.message = `Los siguientes productos están agotados: ${this.productsNoStockname.join(', ') }`;
      }
    }) : this.router.navigate(['/home']);
  */
  }

  public createItem(itemCart: Product){
    return this.formBuilder.group({
      
      itemReference: [itemCart.sk],
      itemImage: [itemCart.image],
      itemPrice:[itemCart.price ],
      itemCount : [itemCart.count],
      itemSubtotal:[ itemCart.price * itemCart.count]
    })
  }

  public addItem(itemCart){
    const items = this.itemFormGroup['controls'].items as FormArray;
    items.push(this.createItem(itemCart));
    this.totalItemsCar.getTotalItemsCar(items.value.length);
  }

  public changeSubTotal(formGroupSelected){
    if (Number.isNaN(parseFloat(formGroupSelected.value['itemCount'])) || parseFloat(formGroupSelected.value['itemCount']) < 0) {
      formGroupSelected.controls['itemCount'].setValue(1)
      this.checkoutCarList.find(item => {
        if(item.reference == formGroupSelected.value['itemReference']){
         item.count = formGroupSelected.value['itemCount'];
         localStorage.setItem("carList",JSON.stringify(this.checkoutCarList));
        }
     });
      this.recalculateTotal();
    }else{
      formGroupSelected.controls['itemSubtotal'].setValue( parseFloat(formGroupSelected.value['itemPrice'])* parseFloat(formGroupSelected.value['itemCount']));
      this.checkoutCarList.find(item=>{
        if(item.sk == formGroupSelected.value['itemReference']){
         item.count=formGroupSelected.value['itemCount']
        }
     }) 
     localStorage.setItem("carList",JSON.stringify(this.checkoutCarList))
     this.recalculateTotal();
    }
  }

  public recalculateTotal(){
    this.total=0;
    this.checkoutCarList.forEach(element => {    
      this.total=this.total+(parseFloat(element.count) * parseFloat(element.price))
    });  
    this.resumeGroup.patchValue({resumenTotal: this.total 
    })
  }
  public updateCar(){
    localStorage.setItem("carList",JSON.stringify(this.checkoutCarList))
  }

  public removeItem(item)
  {
    const control = <FormArray> this.itemFormGroup.controls.items
    control.removeAt(item)
    this.checkoutCarList.splice(item,1)
    this.updateCar();
    // this.updateVariable.updateCarCountVariable(this.checkoutCarList.length)
    this.recalculateTotal();
    this.variableService.getTotalItemsCar(this.totalCar - this.decrement);
    this.toastr.error("Producto eliminado de la bolsa.","", {
      timeOut: 1500,
    });
    this.storageCar = localStorage.getItem("carrito");
    this.finalStoragecar = this.storageCar - this.decrement;
    localStorage.setItem("carrito", this.finalStoragecar);
  }


  public paymentValidation(payment){
    return false;
  }

  public startPayment(){
    this.shop.updateProductVariable({
      checkout:this.itemFormGroup.value.items,
      products:this.checkoutCarList

    });
    this.router.navigate(['/informacion-pago']);
  }
}
