import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { CategoryService } from "../../services/category.service";
import { ActivatedRoute } from "@angular/router";
import { CardsService } from "../../services/cards.service";
import { NGB_DATEPICKER_CALENDAR_FACTORY } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";

@Component({
  selector: "app-module",
  templateUrl: "./module.component.html",
  styleUrls: ["./module.component.scss"],
})
export class ModuleComponent implements OnInit, OnDestroy {
  slides = [];
  private sub: any;
  constructor(
    private category: CategoryService,
    private route: ActivatedRoute,
    private cardsService: CardsService
  ) {}
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      const category = params["category"];
      const subcategory = params["subcategory"];
      this.category.getCategories().subscribe((res) => {
        if (category && subcategory) {
          this.slides = res["body"]
            .find((res) => res.category === category)
            ["sub-categories"].find((res) => res.category === subcategory)
            ["images"].filter((res) => res.active)
            .map((res) => ({ src: res.url, ...res }));
        } else if (category && !subcategory) {
          this.slides = res["body"]
            .find((res) => res.category === category)
            ["images"].filter((res) => res.active)
            .map((res) => ({ src: res.url, ...res }));
        }
      });
      this.cardsService.getProducts().subscribe((resp) => {
        let products = []
        if (category && subcategory) {
          products = resp.body["body"].filter(res=>res.category.toUpperCase() === category && res.subcategory.toUpperCase() === subcategory)
        } else if (category && !subcategory) {
          products = resp.body["body"].filter(res=>res.category.toUpperCase() === category)

        }
        resp.body["success"]
          ? this.cardsService.updateCardProducts(products)
          : [];
      });
    });
  }

  chargeBanner() {}
}
