<div class="row w-100 m-0 main-banner" >
  <div class="col w-100 h-100">
    <div  name="carrousel-container">
      <app-carousel [slides]="slides" class="w-100"></app-carousel>
   </div>
  </div>
</div>
<app-cards></app-cards>
<app-popular-categories></app-popular-categories>
<div class="headerBanner center-style w-100">
  <img width="35%" src ="../../../assets/images/logo.png">
</div>
<app-popular-products></app-popular-products> 