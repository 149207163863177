import { AfterViewInit, Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/shared/models/products';
import { ShopService } from 'src/app/shared/services/shop.service';
import { environment } from 'src/environments/environment';
import { textChangeRangeIsUnchanged } from 'typescript';
import { CardsService } from '../../services/cards.service';
import { VariablesService } from '../../services/variables.service';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit {
  sk:string ='';
  public listProducts;
  public message="";
  public urlS3=environment.urlBucketS3;
  public showMessage:boolean=false;
  public productForm:FormGroup;
  public total:number=0;
  public productsNoStockname:any=[];
  public product:Product ;
  public selectedImage:string;
  public carList:any= [];
  public totalCar;
  public increment: number = 1;
  buyMessage:string ="";
  imageList:any[]=[]
  public innerWidth: any =  window.innerWidth;
  hide:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth>768)
      this.hide = true;
    else  
      this.hide=false;
  }
  constructor(private formBuilder:FormBuilder,
              private cardService:CardsService,
              private router: Router,
              private variableService: VariablesService,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
                this.variableService.totalCar.subscribe(res => {
                  this.totalCar = res;
                });

  }



  ngOnInit(): void {
    this.onResize()
    this.sk= this.route.snapshot.paramMap.get('sk');
    this.buyMessage = `https://api.whatsapp.com/send?phone=573014694747&text=Hola!%20Quiero%20comprar%20el%20producto%3A%20Ref%3A%20${this.sk}`
    this.carList = JSON.parse(localStorage.getItem('carList'));
    this.initForm();
    this.getProduct();
    this.cardService.detail.subscribe(product=>{
      if(product){
        this.sk= product.sk;
        this.carList = JSON.parse(localStorage.getItem('carList'));
        this.initForm();
        this.getProduct();
      }
  
  })
  }

  public getProduct(){   
    this.cardService.searchProducts(this.sk).subscribe(res=>{
      window.scrollTo(0,0);
      this.product=res.body['body'][0]

      this.selectedImage=this.product.image[0];
      this.imageList =this.product.image;
  })


}

  

  public initForm(){
    this.productForm = this.formBuilder.group({
      count : [1]
    });
  }
  public redirect(url){
    this.router.navigate([url]);
  }

  public addToCar(product:Product){
    if(this.productForm.value.count>0) product.count=this.productForm.value.count
    this.updateCar(product);
    
    localStorage.getItem("carrito");
  }

  public updateCar(product:Product){
    this.carList = JSON.parse(localStorage.getItem('carList'));
    let arrayAux:Product[]=[];
    let found:boolean=false
    if (this.carList && this.carList.length >0) {

      this.carList.forEach((item)=>{
        if(item.sk === product.sk) {         
          arrayAux.push(product)
          this.toastr.success("Producto actualizado.", "", {
            timeOut: 1500
          });
          found=true;
        }else{
          arrayAux.push(item);
          
        }
      })  
      if(!found){
        arrayAux.push(product);
        this.variableService.getTotalItemsCar(this.totalCar + this.increment);
            this.toastr.success("Se agregó el producto a la bolsa.", "", {
              timeOut: 1500
          });
      } 

    }else{
        this.variableService.getTotalItemsCar(this.totalCar + this.increment);
            this.toastr.success("Se agregó el producto a la bolsa.", "", {
              timeOut: 1500
          });
      arrayAux.push(product);
    }
    localStorage.setItem("carList",JSON.stringify(arrayAux));
    localStorage.setItem("carrito", JSON.stringify(arrayAux.length));
  }


  public changeImage(image:string){
    this.selectedImage=image;
  }
}
