import { Component, OnInit } from "@angular/core";
import { CatalogService } from "../../services/catalog.service";
import { CognitoService } from "../../services/cognito.service";
import { CardsService } from "../../services/cards.service";

@Component({
  selector: "app-liked-products",
  templateUrl: "./liked-products.component.html",
  styleUrls: ["./liked-products.component.scss"],
})
export class LikedProductsComponent implements OnInit {
  constructor(
    private catalogervice: CatalogService,
    private cardsService: CardsService,

    private auth: CognitoService
  ) {
    this.catalogervice.getLikes(auth.getId()["email"]).subscribe((res) => {
      const iterables = res["body"]["body"]["products"];
      this.cardsService.getProducts().subscribe((resp) => {
        if (resp.body["success"]) {        
          cardsService.updateCardProducts(resp.body["body"].filter((item) => iterables.includes(item.sk) ))
        }
      });
    });
  }

  ngOnInit(): void {}
}
