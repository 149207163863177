import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeConstants } from "src/app/shared/constants/constants";
import { AuthServiceService } from "src/app/shared/services/auth-service.service";
import { CampaignService } from "src/app/shared/services/campaign.service";
import { CardsService } from "src/app/shared/services/cards.service";
import { VariablesService } from "src/app/shared/services/variables.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public homeConstants = HomeConstants;
  public containerNavHeight: number;
  public totalCar;
  public s3banner = environment.urlAssetsS3;
  public innerWidth: any = window.innerWidth;
  hide: boolean = true;

  public slides = [];

  constructor(
    private variablesService: VariablesService,
    private cardsService: CardsService,
    private router: Router,
    private campaign: CampaignService
  ) {
    this.variablesService.containNavbar.subscribe((res) => {
      this.containerNavHeight = res;
    });
    this.cardsService.getProducts().subscribe((resp) => {
      resp.body["success"]
        ? cardsService.updateCardProducts(resp.body["body"])
        : [];
    });
  }

  ngOnInit() {
    this.campaign.getCampaign().subscribe((res) => {
      this.slides = res["body"]["body"]["banner"]
        .filter((res) => res.active)
        .map((res) => ({ src: res.url, ...res }));
    });
  }

  redirect() {
    this.router.navigate(["/navidad"]);
  }
}
