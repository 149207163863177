<div class="style w-100 d-flex align-items-center justify-content-center">
  <div class="row p-5 w-100 d-flex align-items-center justify-content-center">
    <div class="row w-100 m-5">
      <div class="col-12">
        <h3 class="contacto">INFORMACIÓN DE ENVÍO</h3>
      </div>
    </div>
    <div class="row d-flex w-100 align-items-center justify-content-center">
      <div class="card-body">
        <form [formGroup]="userPaymentInfo">
          <label class="font-weight-bold">Tipo de persona</label>
          <small class="text-danger">*</small>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="person"
              id="gridRadios1"
              value="natural"
              formControlName="person"
              (cheked)="(true)"
            />
            <label class="form-check-label" for="gridRadios1">
              Persona Natural
            </label>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="radio"
              name="person"
              id="gridRadios2"
              value="juridica"
              formControlName="person"
            />
            <label class="form-check-label" for="gridRadios2">
              Persona Jurídica
            </label>
          </div>

          <div class="form-row">
            <div
              class="form-group col-md-6"
              *ngIf="userPaymentInfo.value.person == 'juridica'"
            >
              <label for="inputEmail4" class="font-weight-bold">Empresa</label>
              <small class="text-danger">*</small>
              <input
                type="text"
                class="form-control"
                placeholder="Empresa"
                formControlName="enterprise"
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="userPaymentInfo.value.person == 'natural'"
            >
              <label for="inputEmail4" class="font-weight-bold">Nombre</label>
              <small class="text-danger">*</small>
              <input
                type="text"
                class="form-control"
                placeholder="Nombre"
                formControlName="name"
              />
            </div>
            <div
              class="form-group col-md-6"
              *ngIf="userPaymentInfo.value.person == 'natural'"
            >
              <label for="inputEmail4" class="font-weight-bold">Apellido</label>
              <small class="text-danger">*</small>
              <input
                type="text"
                class="form-control"
                placeholder="Apellido"
                formControlName="lastName"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4" class="font-weight-bold">Celular</label>
              <small class="text-danger">*</small>
              <input
                class="form-control"
                placeholder="Celular"
                formControlName="cellphone"
              />
              <small
                class="text-danger"
                *ngIf="
                  userPaymentInfo['controls'].cellphone.status == 'INVALID' &&
                  userPaymentInfo['controls'].cellphone.pristine == false
                "
                >*Celular inválido</small
              >
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4" class="font-weight-bold">Email</label>
              <small class="text-danger">*</small>
              <input
                type="email"
                class="form-control"
                id="inputEmail4"
                placeholder="Correo Electrónico"
                formControlName="email"
                disabled
              />
              <small
                class="text-danger"
                *ngIf="
                  userPaymentInfo['controls'].email.status == 'INVALID' &&
                  userPaymentInfo['controls'].email.pristine == false
                "
                >*Correo electrónico inválido</small
              >
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4" class="font-weight-bold"
                >Tipo de documento</label
              >
              <small class="text-danger">*</small>
              <select
                id="inputState"
                class="form-control"
                formControlName="docType"
              >
                <option selected>Selecciona...</option>
                <option *ngIf="userPaymentInfo.value.person != 'natural'">
                  NIT
                </option>
                <option>Cédula de Ciudadania</option>
                <option *ngIf="userPaymentInfo.value.person == 'natural'">
                  Cedula de extranjería
                </option>
                <option *ngIf="userPaymentInfo.value.person == 'natural'">
                  Pasaporte
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4" class="font-weight-bold"
                >Documento</label
              >
              <small class="text-danger">*</small>
              <input
                type="number"
                class="form-control"
                placeholder="Documento"
                formControlName="document"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress" class="font-weight-bold">Dirección</label>
            <small class="text-danger">*</small>
            <input
              type="text"
              class="form-control"
              id="inputAddress"
              placeholder="Dirección Principal"
              formControlName="address"
            />
          </div>
          <div class="form-group">
            <label for="inputAddress2" class="font-weight-bold"
              >Datos adicionales</label
            >
            <input
              type="text"
              class="form-control"
              id="inputAddress2"
              placeholder="Unidad, apartamento, casa"
              formControlName="additionalAddress"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputCity" class="font-weight-bold"
                >Departamento</label
              >
              <small class="text-danger">*</small>
              <select
                class="form-control"
                id="inputCity"
                formControlName="city"
                (change)="getMunicipality()"
              >
                <option selected></option>
                <option *ngFor="let dep of department">{{ dep }}</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputState" class="font-weight-bold">Ciudad</label>
              <small class="text-danger">*</small>
              <select
                id="inputState"
                class="form-control"
                formControlName="state"
              >
                <option selected></option>
                <option *ngFor="let dep of municipality">{{ dep }}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-8">
            <div class="form-check">
              <!-- [disabled]= "userPaymentInfo.valid" -->
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                (change)="changeValidStatus()"
              />
              <label class="form-check-label" for="flexCheckDefault">
                He leído de manera clara y expresamente acepto y autorizo los
                <a
                  href="https://decorazzia-documents.s3.amazonaws.com/TERMINOS+Y+CONDICIONES+DECORAZZIA.pdf"
                  target="_blank"
                  >términos y condiciones</a
                >,
                <a
                  href="https://decorazzia-documents.s3.amazonaws.com/POL%C3%8DTICA+DE+TRATAMIENTO+DE+DATOS+PERSONALES+DECORAZZIA.pdf"
                  target="_blank"
                >
                  política de tratamiento de datos personales</a
                >
              </label>
            </div>
          </div>
        </form>
        <form #form ngNoForm id="payuForm" method="post" action="{{ urlPayU }}">
          <input name="merchantId" type="hidden" value="{{ merchantId }}" />
          <input name="accountId" type="hidden" value="{{ accountId }}" />
          <input
            name="description"
            type="hidden"
            value="Compra realizada a Decorazzia"
          />
          <input
            name="referenceCode"
            type="hidden"
            value="{{ referenceCode }}"
          />
          <input
            name="amount"
            type="hidden"
            value="{{ this.totalPaymentStr }}"
          />
          <input name="tax" type="hidden" value="{{ tax }}" />
          <input
            name="taxReturnBase"
            type="hidden"
            value="{{ taxReturnBase }}"
          />
          <input name="currency" type="hidden" value="{{ currency }}" />
          <input name="signature" type="hidden" value="{{ signature }}" />
          <input name="test" type="hidden" value="{{ environment }}" />
          <input
            name="buyerEmail"
            type="hidden"
            value="{{ this.buyerEmail }}"
          />
          <input name="responseUrl" type="hidden" value="{{ responseUrl }}" />
          <input
            name="confirmationUrl"
            type="hidden"
            value="{{ confirmationUrl }}"
          />
          <input
            name="paymentMethods"
            type="hidden"
            value="{{ this.paymentMathods }}"
          />
          <input
            name="extra2"
            type="hidden"
            value="{{ this.userPaymentInfo.value.document }}"
          />
        </form>
        <div class="form-group col-md-8">
          <!-- <label *ngIf="showDeliveryMessage">{{deliveryMessage}}{{ delivaryAmount | currency : ''}}</label>      -->
          <!-- <label *ngIf="showPayonDestiny">{{deliveryMessage}}</label>                                                       -->
        </div>
        <button
          class="btn-view"
          [disabled]="!this.validStatus || totalPaymentShow < 10000"
          (click)="createTransaction()"
        >
          PAGAR {{ this.totalPaymentShow | currency : "" }}
        </button>
      </div>
    </div>
  </div>
</div>
