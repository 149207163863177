import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " + btoa(`${environment.cliendId}:${environment.clientSecret}`),
    }),
    observe: "response" as "response",
  };
  private refreshToken: any | null;
  constructor(private http: HttpClient, private router: Router) {}

  getExpiryTime() {
    return localStorage.getItem("access")
      ? jwtDecode(localStorage.getItem("access")).exp
      : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      var d = new Date(expiryTime * 1000); // The 0 there is the key, which sets the date to the epoch
      return d.getTime() - new Date().getTime() < 5000;
    } else {
      return true;
    }
  }

  getToken(code) {
    let bodyParam = new URLSearchParams();
    bodyParam.set("grant_type", "authorization_code");
    bodyParam.set("redirect_uri", environment.redirectLogin);
    bodyParam.set("client_secret", environment.clientSecret);
    bodyParam.set("code", code);
    this.http
      .post(
        `${environment.login}oauth2/token`,
        bodyParam.toString(),
        this.httpOptions
      )
      .subscribe((res) => {
        if (res["body"]["access_token"]) {
          localStorage.setItem("access", res["body"]["access_token"]);
          if (res["body"]["refresh_token"]) {
            this.refreshToken = res["body"]["refresh_token"];
          }
          if (res["body"]["id_token"]) {
            localStorage.setItem("id", res["body"]["id_token"]);
          }
          let context = atob(localStorage.getItem("context"));
          let navigateTo = "/home";
          if (context.includes("http://localhost:4200/")) {
            navigateTo = context.split("http://localhost:4200/")[1];
          } else if (context.includes(environment.env)) {
            navigateTo = context.split(environment.env)[1];
          }
          this.router.navigate([navigateTo]);
        }
      });
  }

  getRefreshToken() {
    let bodyParam = new URLSearchParams();
    bodyParam.set("grant_type", "refresh_token");
    bodyParam.set("client_secret", environment.clientSecret);
    bodyParam.set("refresh_token", this.refreshToken);
    return this.http
      .post(
        `${environment.login}oauth2/token`,
        bodyParam.toString(),
        this.httpOptions
      )
      .subscribe((res) => {
        if (res["body"]["access_token"]) {
          localStorage.setItem("access", res["body"]["access_token"]);
          this.refreshToken = null;
        }
      });
  }
}
