import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  signInForm: FormGroup;
  usernameForm: FormGroup;



  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cognito: CognitoService
  ) { }

  ngOnInit(): void {

    this.usernameForm = this.formBuilder.group({
      username: ["", [Validators.required, Validators.email]],
    });
  }
  forgotPassword(){
    this.cognito.forgotPassword(this.usernameForm.getRawValue())
  }

}
