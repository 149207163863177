import { Component, OnInit } from "@angular/core";
import { CatalogService } from "../../services/catalog.service";
import { CardsService } from "../../services/cards.service";

@Component({
  selector: "app-new-products",
  templateUrl: "./new-products.component.html",
  styleUrls: ["./new-products.component.scss"],
})
export class NewProductsComponent implements OnInit {
  constructor(
    private catalogervice: CatalogService,
    private cardsService: CardsService
  ) {
    this.cardsService.getProducts().subscribe((resp) => {
      if (resp.body["success"]) {
        cardsService.updateCardProducts(
          resp.body["body"].filter((item) => {
            if (item["created"]) {
              var monthAgoTime = new Date().setMonth(new Date().getMonth() - 1);
              let enDate = new Date().setMonth(
                new Date(item["created"]).getMonth()
              );

              var compDate2 = enDate - monthAgoTime;
              if (compDate2 > 0) {
                return item;
              }
            }
          })
        );
      }
    });
  }

  ngOnInit(): void {}
}
