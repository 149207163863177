import { AfterViewInit, Component, OnInit } from "@angular/core";
import { CampaignService } from "../../services/campaign.service";
declare var bootstrap: any;

@Component({
  selector: "app-pop-up",
  templateUrl: "./pop-up.component.html",
  styleUrls: ["./pop-up.component.scss"],
})
export class PopUpComponent implements OnInit {
  image: any = "";
  constructor(private campaign: CampaignService) {}
  ngOnInit(): void {

    if(!localStorage.getItem("campaign")){
      this.campaign.getCampaign().subscribe((res) => {
        this.image = res["body"]["body"]["publicidad"].find((res) => res.active);
      });
      var myModal = new bootstrap.Modal(
        document.getElementById("exampleModalCenter"),
        {}
      );
      document.onreadystatechange = function () {
        myModal.show();
        localStorage.setItem("campaign","true");
      };
    }    
  }
}
