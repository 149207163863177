// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: "dev.decorazzia.com/",
 // url:"https://5iu7p7e6sh.execute-api.us-east-1.amazonaws.com/desarrollo/",
  url:"https://5iu7p7e6sh.execute-api.us-east-1.amazonaws.com/produccion/",
  urlDD: "https://rdg7ris8uf.execute-api.us-east-1.amazonaws.com/production",
  urlBucketS3: "https://s3.amazonaws.com/dev.decorazzia/",
  login: "https://login.decorazzia.com/", //login?client_id=118lgrrvcrdfv8ntpn1h3a1or1&response_type=code&scope=email+openid+phone&redirect_uri=
  //redirectLogin:"http://localhost:4200/login/callback",
  redirectLogin:"https://dev.decorazzia.com/login/callback",
  cliendId:"118lgrrvcrdfv8ntpn1h3a1or1",
  clientSecret:"184qhmnl485rm8k0nsh6oeg70vh7p7d404bcvl3mma0eglb5ini4",
  urlAssetsS3: "https://s3.amazonaws.com/dev.decorazzia.images",
  payU:{
    urlPay:"https://checkout.payulatam.com/ppp-web-gateway-payu",
    apiKey : "B79WcF7428Z9lwj2sz8Ov7xNrB",
    merchant: "879349",
    account:  "887084",
    responseUrl: "https://dev.decorazzia.com/confirmacion-pago",
    confirmationUrl: "https://5iu7p7e6sh.execute-api.us-east-1.amazonaws.com/desarrollo/pagos/confirmacion",
    environment: "0",
    currency:"COP",
    tax: "0",
    taxReturnBase: "0",
    buyerEmail: "",
    paymentTypes:"VISA,MASTERCARD,PSE"
  },
  apikey:'iDHNCThUPv5QQlOoNY7Ri4f21ZI72J1S5dRvxUNU',
  cognitoUserPoolId: 'us-east-1_Z1RdaBkT6',
  cognitoAppClientId: '58p53fs27gro49edpk7dg81vk0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

