import { Component, OnInit } from "@angular/core";
import { CampaignService } from "../services/campaign.service";

@Component({
  selector: "app-top-header",
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.scss"],
})
export class TopHeaderComponent implements OnInit {
  campaignHeader: any = "";
  constructor(private campaign: CampaignService) {
    this.campaign.getCampaign().subscribe((res) => {
      this.campaignHeader = res["body"]["body"]["header"].find(
        (res) => res.active
      )["paragraph"];
    });
  }

  ngOnInit(): void {}
}
