<div class="container-fluid h-50">
  <div class="row d-flex justify-content-center">
    <div class="col-lg-4 col-md-12 mt-5">
      <span
        >Hemos enviado un código de verificación a tu correo electronico,
        ingresalo junto con tu nueva contraseña</span
      >
      <form [formGroup]="signInForm" (ngSubmit)="forgotPassword()">
        <div class="form-group mt-3">
          <label>Código</label>
          <input type="email" class="form-control" formControlName="code" />
        </div>
        <div class="form-group mt-3">
          <label>Contraseña</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
          />
        </div>
        <div class="form-group mt-3">
          <label>Confirmar contraseña</label>
          <input
            type="password"
            class="form-control"
            formControlName="confirmpassword"
            autocomplete="new-password"
          />
        </div>
        <button
          type="submit"
          class="button-style"
          [disabled]="signInForm.invalid"
        >
          Cambiar contraseña
        </button>
      </form>
    </div>
  </div>
</div>
