import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { VariablesService } from "src/app/shared/services/variables.service";
import { CardsService } from "../../services/cards.service";
import { environment } from "src/environments/environment";
import { CategoryService } from "../../services/category.service";
import { ServicesService } from "../../services/services.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit, OnDestroy {
  //login?client_id=118lgrrvcrdfv8ntpn1h3a1or1&response_type=code&scope=email+openid+phone&redirect_uri=
  public totalItems: any;
  public showLink: boolean;
  loginUrl: string = `${environment.login}login?client_id=${
    environment.cliendId
  }&response_type=code&scope=openid+profile&redirect_uri=${encodeURI(
    environment.redirectLogin
  )}`;
  categoriesList: any[] = [];
  servicesList: any[] = [];
  itemDestroy: Subscription;
  searchGroup: FormGroup;

  constructor(
    private variablesService: VariablesService,
    private card: CardsService,
    private route: Router,
    private categories: CategoryService,
    private formBuilder: FormBuilder,
    private services: ServicesService
  ) {
    window.innerWidth < 768 ? (this.showLink = false) : (this.showLink = true);
    this.itemDestroy = this.variablesService.totalCar.subscribe((res) => {
      this.totalItems = res;
    });

    this.totalItems = localStorage.getItem("carrito");
    this.categories.getCategories().subscribe((res) => {
      this.categoriesList = res["body"].filter((res) => !res.hiden);
    });
    this.services.getServices().subscribe((res) => {
      this.servicesList = res["body"]["services"].filter((res) => res.active);
    });

    this.searchGroup = formBuilder.group({
      criteria: ["", Validators.required],
    });
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    window.innerWidth < 768 ? (this.showLink = false) : (this.showLink = true);
  }

  search() {
    if (this.searchGroup.valid)
      this.route.navigate([
        "/productos",
        this.searchGroup.controls["criteria"].value,
        "sale",
      ]);
  }
  ngOnInit() {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.itemDestroy.unsubscribe();
  }

  routeTo(subcategory: String) {
    this.route.navigate([subcategory]);
  }

  redirectLogin() {
    localStorage.setItem("context", btoa(window.location.href));
    this.route.navigate(["/user/profile"]);
  }

  redirectCategories(path) {
    this.route.navigate(["/module", path]);
  }

  redirectSubcategories(category, subcategory) {
    this.route.navigate(["/module", category, subcategory]);
  }
  redirectSubservices(service) {
    this.route.navigate(["/servicios", service]);
  }
}
