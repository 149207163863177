<div class="container h-50 mt-5">
  <div class="row d-flex  align-items-center justify-content-center">
    <div class="card d-flex  align-items-center justify-content-center p-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="55"
        fill="#000"
        class="bi bi-person-circle"
        viewBox="0 0 16 16"
      >
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <path
          fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
        />
      </svg>
      <div class="card-body  d-flex  align-items-center justify-content-center">
        <h5 class="card-title">Bienvenido {{user['given_name']}} {{user['family_name']}}</h5>
       
      </div>
      <button class="btn-view w-100" (click)="signOut()">Cerrar sesión</button>
    </div>
  </div>
</div>
