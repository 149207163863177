<div class="container mt-4  d-flex  align-items-center justify-content-center ">
  <div class="card-columns">
    <div class="card" *ngFor="let ser of this.servicesList">
      <img class="card-img-top" [src]="ser.url" alt="Card image cap" />
      <div class="card-body">
        <h5 class="card-title">{{ser.title}}</h5>
        <p class="card-text">
            {{ser.description}}
        </p>
        <button class="button-style" (click)="details(ser.title)">
          Más información
        </button>
      </div>
    </div>
  </div>
</div>
