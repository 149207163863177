<nav
  class="navbar navbar-expand-lg navbar-light bg-light"
  style="position: relative; z-index: 1"
>
  <a class="navbar-brand" href="#"
    ><img src="assets/images/logo.png" alt="logo" class="navbar__img"
  /></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto w-100">
      <li
        class="nav-item dropdown d-sm-block d-md-block d-lg-none d-xl-none"
        *ngFor="let category of categoriesList; let i = index"
      >
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="redirectCategories(category.category)"
        >
          {{ category.category }}
        </a>
        <div
          class="dropdown-menu"
          aria-labelledby="navbarDropdown"
          *ngIf="category['sub-categories']"
        >
          <a
            class="dropdown-item"
            *ngFor="
              let subCategories of category['sub-categories'];
              let i = index
            "
            (click)="
              redirectSubcategories(category.category, subCategories.category)
            "
            >{{ subCategories.category }}</a
          >
        </div>
      </li>
      <li
        class="d-none d-sm-none d-md-none d-lg-block"
        *ngFor="let category of categoriesList; let i = index"
      >
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="cursor: pointer"
          (click)="redirectCategories(category.category)"
        >
          {{ category.category }}
        </a>
        <div
          class="dropdown-menu"
          aria-labelledby="navbarDropdown"
          *ngIf="category['sub-categories']"
        >
          <div class="row m-0 p-0">
            <div class="col-8">
              <div class="card-columns">
                <div
                  style="min-width: 100px"
                  class="card"
                  *ngFor="
                    let subCategories of category['sub-categories'];
                    let i = index
                  "
                >
                  <div class="card-body">
                    <a
                      style="cursor: pointer"
                      (click)="redirectSubcategories(category.category,subCategories.category )"
                      >{{ subCategories.category }}</a
                    >
                    <div *ngIf="subCategories['clasification']">
                      <small
                        class="card-text"
                        style="cursor: pointer"
                        *ngFor="
                          let class of subCategories['clasification'];
                          let i = index
                        "
                      >
                        {{ class }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 justify-content-end">
              <div *ngFor="let thumb of category['thumbnails']">
                <img class="w-100" *ngIf="thumb" [src]="thumb.url" />
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" (click)="routeTo('servicios')">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          SERVICIOS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a
            class="dropdown-item"
            href="#"
            *ngFor="let service of servicesList; let i = index"
            >{{ service.title }}</a
          >
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="productos/nuevos">LO NUEVO</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://api.whatsapp.com/send?phone=573014694747&text=Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos%20al%20por%20mayor." target="_blank">POR MAYOR</a>
      </li>
    </ul>
  </div>
  <div class="row">
    <div class="col-7">
      <form [formGroup]="searchGroup">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="¿Qué estás buscando?"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            formControlName="criteria"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="search()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 -960 960 960"
                width="14px"
                fill="#000"
              >
                <path
                  d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-1">
      <a (click)="redirectLogin()" target="_blank" class="navbar__icons"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="#000"
          class="bi bi-person-circle"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path
            fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
          />
        </svg>
      </a>
    </div>
    <div class="col-1">
      <a routerLink="productos/likes" class="navbar__icons"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          height="25px"
          viewBox="0 -960 960 960"
          width="25px"
          fill="#000"
        >
          <path
            d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
          />
        </svg>
      </a>
    </div>
    <div class="col-1">
      <a class="badge-position" [routerLink]="['/compras']">
        <img
          class="navbar__icons"
          src="assets/icons/compras.png"
          alt="compras"
        />
        <span class="badge badge-color" *ngIf="totalItems > 0">{{
          totalItems
        }}</span>
      </a>
    </div>
  </div>
</nav>
