<div class="container h-50 mt-5">
  <div class="col">
    <div class="row d-flex justify-content-center">
      <span class="contacto">Acceder</span>
    </div>
    <div class="row d-flex justify-content-center p-3">
      <label
        >Hemos enviado un mail al su correo registrado, verifica en su bandeja
        de entrada y sigue las instrucciones para verificar tu cuenta. Una vez termines las instrucciones, haz clic en <strong>Continuar</strong>.
      </label>
    </div>
    <div class="row d-flex justify-content-center pl-5 p-3">
      <span *ngIf="showLink">No recibiste el link? <a class="navbar__link w-100" >Enviar nuevamente</a></span>
    </div>
    <!-- <div class="row d-flex justify-content-center">
      <div id="inputs" class="inputs">
        <input id="input1" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input2" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)"  (keyup)="onKeyUp($event)"/>
        <input id="input3" class="input" type="text" inputmode="numeric" maxlength="1"  (input)="onSearchChange($event)" (keyup)="onKeyUp($event)" />
        <input id="input4" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input5" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
        <input id="input6" class="input" type="text" inputmode="numeric" maxlength="1"   (input)="onSearchChange($event)" (keyup)="onKeyUp($event)"/>
      </div>      
    </div> -->
    <div class="row mt-4 d-flex justify-content-center">
      <button type="submit" class="button-style" (click)="login()" [disabled]="dissable">
        Continuar
      </button>
    </div>
  </div>
</div>

