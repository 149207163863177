<app-top-header></app-top-header>
<app-nav-bar></app-nav-bar>
<ng-content></ng-content>
<app-footer></app-footer>

<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
/>
<a
  href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos."
  class="float"
  target="_blank"
>
  <i class="fa fa-whatsapp my-float"></i>
</a>
<app-pop-up></app-pop-up>