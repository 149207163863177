<footer>
  <div class="footer__container">
    <div class="row">
      <div class="col-lg-4 col-md-12 mt-4 mb-4">
        <ul class="list-group text-center">
          <li class="list-group-item">
            <strong>CENTRO COMERCIAL GRAN PLAZA</strong>
          </li>
          <li class="list-group-item"><strong>HORARIO NAVIDAD</strong></li>
          <li class="list-group-item">LUNES A DOMINGO</li>
          <li class="list-group-item">DE 10:00 AM A 9:00 PM</li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-12 mt-4 mb-4">
        <ul class="list-group text-center">
          <li class="list-group-item">
            <strong>HORARIO GENERAL</strong>
          </li>
          <li class="list-group-item">LUNES A SÁBADO</li>
          <li class="list-group-item">DE 10:00 AM A 9:00 PM</li>
          <li class="list-group-item">*DOMINGOS Y FERIADOS NO LABORAMOS</li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-12 mt-4 mb-4">
        <ul class="list-group text-center">
          <li class="list-group-item">
            <strong>ATENCIÓN ONLINE</strong>
          </li>
          <li class="list-group-item">LUNES A DOMINGO</li>
          <li class="list-group-item">DE 10:00 AM A 9:00 PM</li>
          <li class="list-group-item">*DOMINGOS Y FERIADOS NO LABORAMOS</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container footer__container_last">
    <div class="row">
      <div class="col-lg-4 col-md-12 mt-5">
        <ul class="list-group text-center text-center">
          <li class="list-group-item">
            <strong>CENTRO COMERCIAL GRAN PLAZA</strong>
          </li>
          <li class="list-group-item"><strong>Piso 9. Local 905b</strong></li>
          <li class="list-group-item">MEDELLIN - ANTIOQUIA</li>
        </ul>
        <ul class="list-group text-center text-center">
          <li class="list-group-item mt-4">
            <strong
              >WHATSAPP
              <a
                href="https://api.whatsapp.com/send?phone=573014694747&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos."
                target="_blank"
                >+57 301 469 4747</a
              ></strong
            >
          </li>
          <li class="list-group-item mt-4">
            <strong
              ><a
                href="https://www.facebook.com/Decorazzia-825801597539175/"
                target="_blank"
                ><img
                  class="navbar__icons"
                  src="assets/icons/facebook.png"
                  alt="facebook"
              /></a>
              <a
                href="https://www.instagram.com/decorazzia/"
                target="_blank"
                class="social-intragram"
                ><img
                  class="navbar__icons"
                  src="assets/icons/instagram.png"
                  alt="instagram"
                /> </a
            ></strong>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-12 mt-5">
        <img class="w-100" src="assets/images/logo.png" alt="logo" />
      </div>
      <div class="col-lg-4 col-md-12 mt-5">
        <ul class="list-group text-center">
          <li class="list-group-item">
            <strong><a [routerLink]="['/compras']">CARRITO</a></strong>
          </li>
          <li class="list-group-item">
            <strong><a [routerLink]="['/login']">MI CUENTA</a></strong>
          </li>
          <li class="list-group-item">
            <strong
              ><a [routerLink]="['/servicios']">NUESTROS SERVICIOS</a></strong
            >
          </li>
          <li class="list-group-item">
            <strong><a>PREGUNTAS FRECUENTES</a></strong>
          </li>
        </ul>
      </div>
    </div>
    <div class="row footer__container_mini">
      <div class="col-lg-3 col-md-6  text-center mt-3">
        <a [routerLink]="['/terminos-condiciones']">TÉRMINOS Y CONDICIONES</a>
      </div>
      <div class="col-lg-3 col-md-6  text-center mt-3">
        <a [routerLink]="['/tratamiento-datos']">POLÍTICAS DE PRIVACIDAD</a>
      </div>
      <div class="col-lg-6 col-md-12 text-center mt-3">
        DECORAZZIA.COM - TODOS LOS DERECHOS RESERVADOS - 2023
      </div>
    </div>
  </div>
</footer>
