<div class="container-fluid w-100"> 
    <div class="row card-alert" *ngIf="showMessage">
        <div class="alert alert-danger" role="alert">
            {{message}}
        </div>
    </div>    
    <div class="row m-0 p-0 mb-5 d-flex justify-content-center" >
            <div class="product-table w-100 d-flex">
                <form  [formGroup]="itemFormGroup" class="w-100" (keydown.enter)="$event.preventDefault()">
                    <div class="row center-card"   formArrayName="items" *ngFor="let item of this.itemFormGroup.controls.items['controls']; let i = index" >             
                        <div class="col w-100 mt-5 "  style="min-height: 250px ;" [formGroupName]="i">                                                      
                            <div class="row w-100 m-0 p-0">
                                <div class="col-sm-12  col-md-12 col-lg-2 text-center mt-5">
                                    <div class="row d-flex justify-content-center">
                                        <span>REFERENTE</span>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-4">
                                        <span>
                                            {{item.value.itemReference}}
                                        </span>                                               
                                    </div>
                                </div>
                                <div class="col-sm-12  col-md-12 col-lg-2 mt-5">
                                    <div class="row d-flex justify-content-center">
                                        <span>PRODUCTO</span>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-4">
                                        <div  class="col product-image">
                                            <img   src="{{item.value.itemImage[0]}}" >
                                        </div>                                                  
                                    </div>
                                </div>
                                <div class="col-sm-12  col-md-12 col-lg-3 mt-5 ">
                                    <div class="row d-flex justify-content-center">
                                        <span>PRECIO UNITARIO</span>
                                    </div>
                                    <div class="row  mt-4 d-flex justify-content-center">
                                        <span class="product-price">
                                            {{item.value.itemPrice | currency : ""}}
                                        </span>                                                    
                                    </div>
                                </div>
                                <div class="col-sm-12  col-md-12 col-lg-2 mt-5">
                                    <div class="row d-flex justify-content-center">
                                        <span>CANTIDAD</span>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-4">
                                        <input class="form-control " formControlName="itemCount" min="1" type="number" (change)="changeSubTotal(item)"  id="example-number-input">                                
                                        <small *ngIf="item.value['itemCount']<0" class="text-danger">*Valor inválido</small>
                                    </div>
                                </div>
                                <div class="col-sm-12  col-md-12 col-lg-2 mt-5 ">
                                    <div class="row d-flex justify-content-center">
                                        <span> SUBTOTAL</span>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-4">
                                        <span class="product-price">{{item.value.itemSubtotal | currency : ""}}</span>
                                    </div>
                                </div>  
                                <div class="col-lg-1 mt-5 ">                         
                                    <div class="close-button d-flex justify-content-center .d-md-none .d-sm-none " (click)="removeItem(i)"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" class="bi bi-x-square" style="color: gray;" viewBox="0 0 16 16">
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                    </div> 
                                </div>  
                            </div>
                           
                        </div>                                                
                        <div class="row w-100">
                            <div class="dropdown-divider w-100"></div>                                                                                                                                 
                        </div>
                    </div>  
                </form>
        </div>
    </div>
    
    <div class="row resumen-compras m-0 p-0 mb-5 d-flex justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6" >
            <div class="row">
                <div class="col-12 ">
                    <span class="resume-tittle"> RESUMEN DE COMPRA</span>
                </div>
            </div>
            <div class="row resumen-description mt-5">
                <div class="col-sm-12 col-md-12 col-lg-6"> 
                         <h4>
                            Estas a un paso de llenar tus espacios de luz.

                         </h4>
                    <small>
                        *TODOS NUESTROS ARTICULOS TIENEN ENVÍO INCLUIDO <br>
                        *LA GARANTÍA DE LOS PRODUCTOS ES DE 3 MESES<br>
                        * TIEMPO DE ENTREGA 7 DÍAS HABILES 
                    </small>
                       
                        
                                   
                </div>             
                <div class="col-sm-12 col-md-12 col-lg-6 ">
                    <div class="row" style="text-align: center;">
                        <div class="col" >
                            <span class="resume-tittle"> TOTAL</span>
                        </div>
                    </div>    
                    <div class="row ">                     
                        <div class="col d-flex justify-content-center">
                            <span class="resume-price">  {{resumeGroup.value.resumenTotal | currency : ""}}</span>
                                                         
                        </div>                          
                    </div> 
                    <div class="row mt-4">
                        <div class="col d-flex justify-content-center">
                            <button class="btn-view"  *ngIf="this.checkoutCarList.length>=1"  (click) = "startPayment()"   [disabled]="resumeGroup.value['resumenTotal']<10000" >
                                <span>
                                    INICIAR PAGO
                                </span>
                            </button>  
                        </div>                                      
                    </div>    
                </div>
            </div>
                  
        </div>
    </div> 
</div>

<app-popular-products></app-popular-products>

