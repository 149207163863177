import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Product } from '../models/products';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private subcatSelected = new BehaviorSubject<string>('');
  public  subcategory = this.subcatSelected.asObservable();


  private cardProducts = new BehaviorSubject<any>([]);
  public  cards = this.cardProducts.asObservable();

  private productDetail = new BehaviorSubject<Product>(undefined);
  public  detail = this.productDetail.asObservable();


  
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey
    }),
    observe: 'response' as 'response',
  };

  constructor(private http: HttpClient) { }

  private url = environment.url;

  public updateCardProducts(cardService){
    let prod = [... cardService.filter(prod=> ( prod.category!=='Navidad')?true:false).sort( () => .5 - Math.random() ), 
                ...cardService.filter(prod=> (prod.category==='Navidad')?true:false).sort( () => .5 - Math.random() )]


    this.cardProducts.next(prod);
  
  
  }

  
  public updateSubcategory(cardService){
    this.subcatSelected.next(cardService);
  }
  
  public updateProductDetail(detailService){
    this.productDetail.next(detailService);
  }


  public getProducts(){
    return this.http.get<Product>(`${this.url}productos`, this.httpOptions);
  }

  public searchProducts(sk){
    return this.http.get(`${this.url}productos/search?sk=${sk}`,  this.httpOptions)
  }
  
}
