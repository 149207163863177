import { Component, OnInit } from "@angular/core";
import { CatalogService } from "../../services/catalog.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-popular-categories",
  templateUrl: "./popular-categories.component.html",
  styleUrls: ["./popular-categories.component.scss"],
})
export class PopularCategoriesComponent implements OnInit {
  topCategories: any[] = [];
  constructor(private products: CatalogService, private route: Router) {
    this.products.getCatalog().subscribe((response) => {
      if (response.body["success"]) {
        let products = response.body["body"];
        products = products.reduce((result: any, currentValue: any) => {
          (result[currentValue["subcategory"]] =
            result[currentValue["subcategory"]] || []).push(currentValue);
          return result;
        }, {});
        Object.keys(products).forEach((key) => {
          let sum = products[key].reduce((a, b) => {
            return a + b.view;
          }, 0);
          this.topCategories.push({
            name: key,
            category:products[key][0].category,
            count: sum,
            image: products[key][0].image[0],
          });
        });
        this.topCategories = this.topCategories
          .sort((a, b) => b.count - a.count)
          .slice(0, 4);
      }
    });
  }

  redirectSubcategories(category, subcategory) {
    this.route.navigate(["/module", category.toUpperCase(), subcategory.toUpperCase()]);
  }

  ngOnInit(): void {}

  
}
