<div class="container-fluid w-75">
  <div class="row">
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Acceder</span>
      <form [formGroup]="signInForm" (ngSubmit)="signIn()">
        <div class="form-group">
          <label class="font-weight-bold">Usuario</label>
          <small class="text-danger">*</small>
          <input type="text" class="form-control" formControlName="username" />
        </div>
        <div class="form-group">
          <label class="font-weight-bold">Contraseña</label>
          <small class="text-danger">*</small>
          <input
            type="password"
            class="form-control"
            formControlName="password"
          />
        </div>
        <div class="row p-3">
          <a class="navbar__link w-100"  href="/resetear-password" >Olvidaste tu contraseña?</a>
        </div>
        <button
          type="submit"
          class="button-style"
          [disabled]="signInForm.invalid"
        >
          Ingresar
        </button>
      </form>
    </div>
    <div class="col-lg-6 col-sm-12 mt-5">
      <span class="contacto">Registrarse</span>
      <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
        <div class="form-group">
          <label class="font-weight-bold">Email</label>
          <small class="text-danger">*</small>
          <input type="email" class="form-control" formControlName="username" />
        </div>
        <div class="form-group">
          <label class="font-weight-bold">Nombre</label>
          <small class="text-danger">*</small>
          <input
            type="text"
            class="form-control"
            formControlName="given_name"
          />
        </div>
        <div class="form-group">
          <label class="font-weight-bold">Apellido</label>
          <small class="text-danger">*</small>
          <input
            type="text"
            class="form-control"
            formControlName="family_name"
          />
        </div>
        <div class="form-group">
          <label for="inputEmail4" class="font-weight-bold">Género</label>
          <small class="text-danger">*</small>
          <select class="form-control" formControlName="gender">
            <option selected>Selecciona...</option>
            <option>Femenino</option>
            <option>Masculino</option>
          </select>
        </div>
        <div class="form-group">
          <label class="font-weight-bold">Celular</label>
          <small class="text-danger">*</small>
          <input
            type="tel"
            class="form-control"
            formControlName="phone_number"
            placeholder="Ejemplo: +573102531"
            name="celular"
          />
        </div>
        <div class="form-group">
          <label class="font-weight-bold">Fecha de nacimiento</label>
          <small class="text-danger">*</small>
          <input type="date" class="form-control" formControlName="birthdate"/>
        </div>

        <div class="form-group">
          <label class="font-weight-bold">Contraseña</label>
          <small class="text-danger">*</small>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            autocomplete="new-password"
          />
        </div>
        <button
          type="submit"
          class="button-style"
          [disabled]="signUpForm.invalid"
        >
          Registrarse
        </button>
      </form>
    </div>
  </div>
</div>
