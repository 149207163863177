<div class="container mt-5 mb-5">
    <div class="row">
        <img class="logoDecorazzia" src="assets\images\24.png" alt="logo">
    </div>
    <div class="row-lg-12 margin">
        <h4 class="letter-style">
           {{message}}
        </h4>
    </div>
    <div class="row-lg-12 margin">
        <div class="col mensaje">
            <div class="row">
                <div class="col">
                    <h6>Código de transacción:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.referenceCode}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>Cliente:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.customer}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>Descripción:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.description}}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h6>Valor:</h6>
                </div>
                <div class="col">
                    <h6>{{transaction.amount | currency : 'COP'}}</h6>
                </div>
            </div>
        </div> 
        

    </div>   
    <div class="row margin">
        <button class="btn-view"  [disabled] ="!confirmed"  (click) = "redirect('/home')" >
            <span class="material-icons">
                SEGUIR COMPRANDO
            </span>
        </button>  
    </div>
</div>
<app-popular-products></app-popular-products>
